import React, {Component} from 'react';
import {Col} from 'reactstrap';
import CountdownNow from 'react-countdown-now';
import Cookies from "js-cookie";
import classes from './Countdown.module.scss';

class Countdown extends Component{
    constructor(props) {
        super(props);
        this.state = {
            animate : false,
            animateMessage: 'Jetzt mit SMS-Code abstimmen!'
        }
    }

    daysInHours;

    componentDidUpdate() {
        if (Cookies.get('bigfoot_code-requested') && !this.state.animate) {
            this.animateHandler(true);
        } else if (!Cookies.get('bigfoot_code-requested') && this.state.animate){
            this.animateHandler(false);
        }
    }

    animateHandler = (animateValue) => {
        this.setState(({
            animate: animateValue
        }));
    }

    render() {
        let phaseDate = '';
        let countdownMessage = '';
        if(Object.keys(this.props.votingPhases).length !== 0){
            if (!this.props.votingPhases.voteStarted) {
                // the voting has NOT yet started
                phaseDate = this.props.votingPhases.voteStart;
                countdownMessage = 'bis zur Abstimmung';
            } else if (!this.props.votingPhases.voteEnded) {
                if (this.props.votingPhases.type.length > 1) {
                    // it will have a finale
                    if (this.props.votingPhases.voteStartedFinale) {
                        // the finale started
                        phaseDate = this.props.votingPhases.voteEnd;
                        countdownMessage = 'bis zum Ende';
                    } else {
                        // the finale has not yet started
                        phaseDate = this.props.votingPhases.voteStartFinale;
                        countdownMessage = 'bis zum Finale';
                    }
                } else {
                    // it will NOT have a finale
                    phaseDate = this.props.votingPhases.voteEnd;
                    countdownMessage = 'bis zum Ende';
                }
            } else {
                countdownMessage = 'ist beendet';
            }
        }

        const renderer = ({days, hours, minutes, seconds}) => {
            if (!this.props.votingPhases.voteEnded) {
                if (days < 2) {
                    this.daysInHours = true;
                    return <span>Noch {days} Tag(e) und {hours}:{minutes}:{seconds + ' ' + countdownMessage} </span>; // until vote start
                } else {
                    return <span>Noch {days + ' Tage ' + countdownMessage} </span>;
                }
            } else {
                return <span>Der Wettbewerb ist beendet</span>;
            }
        };
        return (
            <Col className={'text-white bg-secondary px-0 py-1 text-center'}>
                <div className={this.state.animate ? classes.CountdownWrapper : ""}>
                    <div className={this.state.animate ? `${classes.animatedElement}  ${classes.CountdownTimer}`: ""}>
                        <CountdownNow
                            date={phaseDate}
                            renderer={renderer}
                            daysInHours={this.daysInHours}
                        />
                    </div>
                    {this.state.animate && <p className={`${classes.animatedElement} ${classes.CountDownMessage}`}>{this.state.animateMessage}</p>}
                </div>
            </Col>
        );
    }


}

export default Countdown;
